import React, { useState, useEffect, useContext } from 'react';
import { data_vals, group_by, aggregationOptions, datetimeGroupByOptions, dataPeriods } from './data';
import { InputSwitch } from 'primereact/inputswitch';
import { TreeSelect } from 'primereact/treeselect';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Chart, useAPI } from 'components/lib'; // Import the Chart component
import { AuthContext } from 'components/lib'; // Import AuthContext
import axios from 'axios';
import { GithubPicker } from 'react-color'; // Import SketchPicker
import './ChartSettingsForm.css';
import { BarChart as MuiBarchart } from '@mui/x-charts/BarChart';


const filterOperators = [
  { label: '= (equals)', value: '=' },
  { label: '!= (not equal to)', value: '!=' },
  { label: '< (less than)', value: '<' },
  { label: '<= (less than or equal to)', value: '<=' },
  { label: '> (greater than)', value: '>' },
  { label: '>= (greater than or equal to)', value: '>=' },
  { label: 'starts with', value: 'startswith' },
  { label: 'in (is in the list)', value: 'in' }
];

const filterValues = [
  { label: '"" (blank value)', value: '""' },
  { label: 'True', value: 'True' },
  { label: 'False', value: 'False' },
  { label: 'Text (matches)', value: 'text' },
  { label: 'Numeric Value', value: 'numeric' },
  { label: 'Select a Field', value: 'field' },
  { label: 'Select a Date', value: 'date' }
];

const logicalOperators = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' }
];

const colorOptions = [
  { label: 'Red', value: '#FF0000' },
  { label: 'Green', value: '#00FF00' },
  { label: 'Blue', value: '#0000FF' },
  { label: 'Yellow', value: '#FFFF00' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Purple', value: '#800080' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'Gray', value: '#808080' },
  { label: 'Black', value: '#000000' },
  { label: 'Teal', value: '#008080' },
  { label: 'Navy', value: '#000080' },
  { label: 'Olive', value: '#808000' },
  { label: 'Maroon', value: '#800000' },
  { label: 'Lime', value: '#00FF00' },
  { label: 'Aqua', value: '#00FFFF' },
  { label: 'Fuchsia', value: '#FF00FF' },
  { label: 'Silver', value: '#C0C0C0' },
  { label: 'Gold', value: '#FFD700' },
  { label: 'Coral', value: '#FF7F50' }
];

export function ChartSettingsForm({ item, onSave, onCancel }) {
  const authContext = useContext(AuthContext); // Use AuthContext
  const [chartType, setChartType] = useState(item.chartType);
  const [chartTitle, setChartTitle] = useState(item.chartTitle || '');
  const [dataGroups, setDataGroups] = useState(item.queryOptions?.dataGroups || [{ dataType: 'GL Reports', aggregation: 'SUM', dataVal: '', groupBy: '', rowLimit: null, groupByTime: undefined, filters: [] }]);
  const [showLegend, setShowLegend] = useState(item.queryOptions?.showLegend || false);
  const [dataVals, setDataVals] = useState(data_vals);
  const [groupByOptions, setGroupByOptions] = useState(group_by);
  const [chartData, setChartData] = useState(null); // State for chart data
  const [noDataMessage, setNoDataMessage] = useState(''); // State for no data message
  const [customStartDate, setCustomStartDate] = useState(null); // Define customStartDate
  const [customEndDate, setCustomEndDate] = useState(null); // Define customEndDate
  const [selectedPeriod, setSelectedPeriod] = useState("Last 12 Months"); // Define selectedPeriod
  const [filters, setFilters] = useState(item.queryOptions?.filters || []); // Define filters
  const [selectedColor, setSelectedColor] = useState(item.color || 'blue'); // Define selectedColor
  const [showColorPicker, setShowColorPicker] = useState(false); // State to show/hide color picker
  const [dataTypes, setDataTypes] = useState([]);
  
  const dataTypesAPI = useAPI('/api/appData/dataTypes/');

  useEffect(() => {
    console.log('Data Groups:', JSON.stringify(dataGroups));
    if (dataGroups[0].dataType !== 'Accounts') {
      fetchFieldList(dataGroups[0].dataType);
    }
    fetchChartData(); // Fetch chart data on mount and when dataGroups change
  }, [dataGroups, chartType, chartTitle, showLegend, selectedPeriod, customStartDate, customEndDate, filters]);

  useEffect(() => {
    if (dataTypesAPI?.data?.length) {
      console.log('Data Types:', JSON.stringify(dataTypesAPI.data));
      const formattedDataTypes = dataTypesAPI.data.map(item => ({
        value: item.name[0],
        label: item.name[0]
      }));
      formattedDataTypes.push({ value: 'GL Reports', label: 'GL Reports' });
      setDataTypes(formattedDataTypes);
    }
  }, [dataTypesAPI.data]);

  const handleSave = () => {
    const updatedItem = { 
      ...item, 
      chartType, 
      chartTitle, 
      queryOptions: { dataGroups, showLegend, filters },
      color: selectedColor
    };
    onSave(updatedItem);
  };

  const addDataGroup = () => {
    setDataGroups([...dataGroups, { dataType: dataTypes[0]?.value || 'Accounts', aggregation: 'SUM', dataVal: '', groupBy: '', rowLimit: null, groupByTime: undefined, filters: [] }]);
    setDataVals(data_vals);
    setGroupByOptions(group_by);
  };

  const removeDataGroup = (index) => {
    if (window.confirm('Are you sure you want to delete this data group?')) {
      setDataGroups(dataGroups.filter((_, i) => i !== index));
    }
  };

  const updateDataGroup = (index, field, value) => {
    const newDataGroups = [...dataGroups];
    newDataGroups[index][field] = value;
    setDataGroups(newDataGroups);

    if (field === 'dataType') {
      console.log('Data Type: - ', value);
      // if (value === "GL Reports") {
      //   console.log('GL Reports - ' + JSON.stringify(data_vals));
      //   setDataVals(data_vals);
      //   setGroupByOptions(group_by);
      // } else {
        fetchFieldList(value);
      // }
    }
  };

  const getFilteredDataVals = (aggregation) => {
    console.log('Aggregation:', aggregation);
    const filterValues = (vals) => {
      return vals.reduce((acc, val) => {
        if (val.dataType === 'number') {
          acc.push(val);
        }
        if (val.children) {
          acc.push(...filterValues(val.children));
        }
        return acc;
      }, []);
    };

    if (aggregation?.toLowerCase() === 'sum' || aggregation?.toLowerCase() === 'avg') {
      return filterValues(dataVals);
    }
    return dataVals;
  };

  const fetchFieldList = async (recordType) => {
    try {
      let fields = [];
      let group_by_fields = [];
      if(recordType === 'GL Reports') {
        fields = data_vals;
        group_by_fields = group_by;
      } else {
        const response = await axios.get(`/api/appData/fieldList/${recordType}`);
        fields = response.data.data;
        group_by_fields = fields;
      }
      // console.log('Fields:', JSON.stringify(fields));
      setDataVals(fields);
      setGroupByOptions(group_by_fields);
    } catch (error) {
      console.error('Error fetching field list:', error);
    }
  };

  const fetchChartData = async () => {
    const { start_date, end_date } = getPeriodDates(selectedPeriod); // Use selectedPeriod
    let url = `/api/appData/reportData/${authContext.user.app_connection_id}?dataType=${dataGroups[0].dataType}&dataGroups=${JSON.stringify(dataGroups)}&filters=${JSON.stringify(filters)}`;
    if (start_date && end_date) {
      url += `&start_date=${start_date.toISOString()}&end_date=${end_date.toISOString()}`;
    }
    if (dataGroups[0].rowLimit) {
      url += `&rowLimit=${dataGroups[0].rowLimit}`;
    }
    console.log(url);
    try {
      const response = await axios.get(url);
      if (response.data.data && response.data.data.datasets.length > 0) {
        setChartData(response.data.data);
        setNoDataMessage('');
      } else {
        setChartData(null);
        setNoDataMessage('No data available for the selected settings.');
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setNoDataMessage('Error fetching data.');
    }
  };

  const getPeriodDates = (period) => {
    const now = new Date();
    let start_date, end_date;

    switch (period) {
      case 'This Month':
        start_date = new Date(now.getFullYear(), now.getMonth(), 1);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last Month':
        start_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end_date = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'This Quarter':
        const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
        start_date = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), currentQuarter * 3, 0);
        break;
      case 'Last Quarter':
        const lastQuarter = Math.floor((now.getMonth() + 3) / 3) - 1;
        start_date = new Date(now.getFullYear(), (lastQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), lastQuarter * 3, 0);
        break;
      case 'This Year':
        start_date = new Date(now.getFullYear(), 0, 1);
        end_date = new Date(now.getFullYear(), 11, 31);
        break;
      case 'Last Year':
        start_date = new Date(now.getFullYear() - 1, 0, 1);
        end_date = new Date(now.getFullYear() - 1, 11, 31);
        break;
      case 'Last 12 Months':
        start_date = new Date(now.getFullYear(), now.getMonth() - 11, 0);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 3 Years':
        start_date = new Date(now.getFullYear() - 3, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 5 Years':
        start_date = new Date(now.getFullYear() - 5, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        start_date = customStartDate;
        end_date = customEndDate;
        break;
    }

    return { start_date, end_date };
  };

  const findOptionInTree = (tree, key) => {
    for (const node of tree) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const found = findOptionInTree(node.children, key);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const addFilter = (groupIndex) => {
    const newDataGroups = [...dataGroups];
    newDataGroups[groupIndex].filters.push({ field: '', operator: '=', value: '', logicalOperator: 'AND' });
    setDataGroups(newDataGroups);
  };

  const removeFilter = (groupIndex, filterIndex) => {
    const newDataGroups = [...dataGroups];
    newDataGroups[groupIndex].filters = newDataGroups[groupIndex].filters.filter((_, i) => i !== filterIndex);
    setDataGroups(newDataGroups);
  };

  const updateFilter = (groupIndex, filterIndex, field, value) => {
    const newDataGroups = [...dataGroups];
    newDataGroups[groupIndex].filters[filterIndex][field] = value;
    setDataGroups(newDataGroups);
  };

  const checkExpressionVal = (groupIndex, filterIndex, value) => {
    const newDataGroups = [...dataGroups];
    const filter = newDataGroups[groupIndex].filters[filterIndex];
    if (['date', 'field', 'text', 'numeric'].includes(value)) {
      filter.expressionVal = value;
    } else {
      filter.expressionVal = null;
      filter.value = value;
    }
    setDataGroups(newDataGroups);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  return (
    <div className="chart-settings-form">
      <div className="form-header">
        <h2>Report Settings</h2>
        <div className="button-group">
          <button className="save-button" onClick={handleSave}>Save</button>
          <button className="cancel-button" onClick={onCancel}>Close</button>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="chart-preview">
          <div style={{width: '100%', textAlign: 'center'}}>
          {chartData ? (
            <>
            <div className="form-row">
            
            <div className="form-group">
              <label>Type:</label>
              <select value={chartType} onChange={e => setChartType(e.target.value)}>
                <option value="bar">Bar Chart</option>
                <option value="line">Line Chart</option>
                <option value="pie">Pie Chart</option>
                <option value="donut">Donut</option>
                <option value="stat">Statistic</option>
                {/* Add more chart types as needed */}
              </select>
            </div>
            <div className="form-group">
              <label>Display Legend:</label>
              <InputSwitch checked={showLegend} onChange={e => setShowLegend(e.value)} />
            </div>
            <div className="form-group">
              <label>Period:</label>
              <Dropdown value={selectedPeriod} options={dataPeriods.map(period => ({ label: period, value: period }))} onChange={(e) => setSelectedPeriod(e.value)} placeholder="Select Period" />
            </div>
            {selectedPeriod === 'Custom' && (
              <>
                <div className="form-group">
                  <label>Start Date:</label>
                  <Calendar value={customStartDate} onChange={(e) => setCustomStartDate(e.value)} view="month" dateFormat="mm/yy" placeholder="Start Date" />
                </div>
                <div className="form-group">
                  <label>End Date:</label>
                  <Calendar value={customEndDate} onChange={(e) => setCustomEndDate(e.value)} view="month" dateFormat="mm/yy" placeholder="End Date" />
                </div>
              </>
            )}
            {/* <div className="form-group">
              <label>Color:</label>
              <button onClick={() => setShowColorPicker(!showColorPicker)} style={{ backgroundColor: selectedColor, color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}>
                Select Color
              </button>
              {showColorPicker && (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                  <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={() => setShowColorPicker(false)} />
                  <GithubPicker
                    color={selectedColor}
                    onChangeComplete={handleColorChange}
                    presetColors={colorOptions.map(option => option.value)}
                  />
                </div>
              )}
            </div> */}
          </div>
          {chartType == 'stat' && <div>hello</div>}
          {chartType !== 'stat' && 
          <div style={{width: '100%', height: '300px'}}>
          
            <Chart
              type={chartType}
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: showLegend,
                  position: 'right'
                },
                title: {
                  display: true,
                  text: chartTitle
                }
              }}
            />

{/* <MuiBarchart
  series={chartData != null ? chartData.datasets : []}
  style={{ minWidth: '200px', border: '1px solid #e0e0e0', padding: '10px' }}
  xAxis={[{ data: chartData?.labels, scaleType: 'band' }]}
  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
  borderRadius={4}
/> */}

            </div>
          }
            </>
          
          ) : (
            <p>{noDataMessage}</p>
          )}
        </div>
        </div>
        <div className="scrollable-content">
          <div className="form-group">
            <label>Chart Title:</label>
            <input type="text" value={chartTitle} onChange={e => setChartTitle(e.target.value)} />
          </div>
          {dataGroups.map((group, index) => (
            <div key={index} className="data-group">
              <div className="data-group-header">
                {dataGroups.length > 1 && (
                  <button className="remove-button" onClick={() => removeDataGroup(index)}>
                    <i className="pi pi-trash"></i>
                  </button>
                )}
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>Data Type:</label>
                  <select value={group.dataType} onChange={e => updateDataGroup(index, 'dataType', e.target.value)}>
                    {dataTypes.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Aggregation:</label>
                  <select value={group.aggregation} onChange={e => updateDataGroup(index, 'aggregation', e.target.value)}>
                    {aggregationOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
                {group.aggregation !== 'COUNT' && (
                  <div className="form-group">
                    <label>Data Value:</label>
                    <TreeSelect 
                      value={group.dataVal} 
                      options={getFilteredDataVals(group.aggregation)} 
                      onChange={e => updateDataGroup(index, 'dataVal', e.value)} 
                      placeholder="Select Data Value" 
                    />
                  </div>
                )}
                <div className="form-group">
                  <label>Group By:</label>
                  <TreeSelect value={group.groupBy} options={groupByOptions} onChange={e => updateDataGroup(index, 'groupBy', e.value)} placeholder="Select Group By" />
                </div>
                <div className="form-group">
                  <label>Limit Record Count to:</label>
                  <InputNumber value={group.rowLimit} onChange={e => updateDataGroup(index, 'rowLimit', e.value)} placeholder="All" />
                </div>
              </div>
              {group.groupBy && findOptionInTree(groupByOptions, group.groupBy)?.dataType === 'datetime' && (
                <div className="form-group">
                  <label>Group By Time:</label>
                  <select value={group.groupByTime || ''} onChange={e => updateDataGroup(index, 'groupByTime', e.target.value || undefined)}>
                    {datetimeGroupByOptions.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              )}
              <div className="form-group">
                <label>Filters:</label>
                {group.filters.map((filter, filterIndex) => (
                  <div key={filterIndex} className="filter-group">
                    <div className="form-group">
                      <label>Field:</label>
                      <TreeSelect 
                        value={filter.field} 
                        options={dataVals} 
                        onChange={e => updateFilter(index, filterIndex, 'field', e.value)} 
                        placeholder="Select Field" 
                      />
                    </div>
                    <div className="form-group">
                      <label>Operator:</label>
                      <Dropdown value={filter.operator} options={filterOperators} onChange={(e) => updateFilter(index, filterIndex, 'operator', e.value)} placeholder="Select Operator" />
                    </div>
                    <div className="form-group">
                      <label>Value:</label>
                      {filter.expressionVal !== 'field' && filter.expressionVal !== 'date' && filter.expressionVal !== 'text' && filter.expressionVal !== 'numeric' && (
                        <Dropdown value={filter.value} options={filterValues} onChange={(e) => checkExpressionVal(index, filterIndex, e.value)} placeholder="Select Value" />
                      )}
                      {filter.expressionVal === 'field' && (
                        <span>
                          <TreeSelect value={filter.value} options={dataVals} onChange={(e) => updateFilter(index, filterIndex, 'value', e.value)} placeholder="Select Field" />
                          <button className="remove-button" onClick={() => checkExpressionVal(index, filterIndex, '')}>Clear</button>
                        </span>
                      )}
                      {filter.expressionVal === 'date' && (
                        <span>
                          <Calendar value={filter.value} dateFormat="mm/dd/yy" onChange={(e) => updateFilter(index, filterIndex, 'value', e.value)} />
                          <button className="remove-button" onClick={() => checkExpressionVal(index, filterIndex, '')}>Clear</button>
                        </span>
                      )}
                      {filter.expressionVal === 'text' && (
                        <span className="p-input-icon-right">
                          <i className="pi pi-times" onClick={() => checkExpressionVal(index, filterIndex, '')} />
                          <InputText value={filter.value} onChange={(e) => updateFilter(index, filterIndex, 'value', e.target.value)} />
                        </span>
                      )}
                      {filter.expressionVal === 'numeric' && (
                        <span className="p-input-icon-right">
                          <i className="pi pi-times" onClick={() => checkExpressionVal(index, filterIndex, '')} />
                          <InputText keyfilter="num" value={filter.value} onChange={(e) => updateFilter(index, filterIndex, 'value', e.target.value)} />
                        </span>
                      )}
                    </div>
                    {filterIndex > 0 && (
                      <div className="form-group">
                        <label>Logical Operator:</label>
                        <Dropdown value={filter.logicalOperator} options={logicalOperators} onChange={(e) => updateFilter(index, filterIndex, 'logicalOperator', e.value)} placeholder="Select Logical Operator" />
                      </div>
                    )}
                    {group.filters.length > 0 && (
                      <button className="remove-button" onClick={() => removeFilter(index, filterIndex)}>Remove</button>
                    )}
                  </div>
                ))}
                <button className="add-button" onClick={() => addFilter(index)}>Add Filter</button>
              </div>
            </div>
          ))}
          <button className="add-button" onClick={addDataGroup}>Add Data</button>
          
        </div>
      </div>
    </div>
  );
}
