import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChart as MuiBarchart } from '@mui/x-charts/BarChart';

export function BarChart(props){

  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;

  console.log(props.data);
//   return <MuiBarchart
//   series={props.data.datasets}
//   style={{ minWidth: '200px', border: '1px solid #e0e0e0' }}
//   xAxis={[{ data: props.data.labels, scaleType: 'band' }]}
//   margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
//   borderRadius={4}
// />

return (
<>
<Bar data={ props.data } options={ options } />
{/* <MuiBarchart
  series={props.data.datasets}
  style={{ minWidth: '200px', border: '1px solid #e0e0e0' }}
  xAxis={[{ data: props.data.labels, scaleType: 'band' }]}
  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
  borderRadius={4}
/> */}
</>
)


// return <BarChart
//   series={[
//     { data: [35, 44, 24, 34] },
//     { data: [51, 6, 49, 30] },
//     { data: [15, 25, 30, 50] },
//     { data: [60, 50, 15, 25] },
//   ]}
//   height={290}
//   xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
//   margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
// />
}
