import React, { useState, useEffect, useRef, useContext } from 'react';
import '@mdxeditor/editor/style.css'
import { MDXEditor, headingsPlugin, toolbarPlugin, UndoRedo, BoldItalicUnderlineToggles, BlockTypeSelect, CreateLink, linkDialogPlugin, InsertTable, tablePlugin, InsertThematicBreak, thematicBreakPlugin, ListsToggle, listsPlugin, imagePlugin, jsxPlugin, codeBlockPlugin, codeMirrorPlugin, ConditionalContents, ChangeCodeMirrorLanguage } from '@mdxeditor/editor'
import { AiOutlineRobot } from 'react-icons/ai';
import { AuthContext } from 'components/lib'; // Import AuthContext
import { MDXProvider } from '@mdx-js/react'; // Import MDXProvider
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'; // Replace import
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { dataPeriods, chartOptions, defaultCharts, data_vals, group_by, record_type, aggregationOptions, datetimeGroupByOptions } from '../report/data'; // Import dataPeriods and defaultCharts
import FeatherIcon from 'feather-icons-react';
import { ChartSettingsForm } from '../report/ChartSettingsForm'; // Import ChartSettingsForm
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import axios from 'axios';
import './aicanvas.css'; // Add this line
import { Chart } from 'components/lib'; // Import the Chart component

const initialContent = `
## Sample Chart

Below is a sample chart:


You can write text before and after the chart.
`.trim();

export function AiCanvas() {
  const [content, setContent] = useState('## Hello, MDX export default function App() {\
  return (<div>\
  <p>This is a live React component, thats being previewed in codesandbox. </p> \
  <p>Editing it will update the fenced codeblock in the fdgffd.</p> \
  </div>) \
} <h2>hola</h2> Editor!\nStart editing here...');
  const [selectedText, setSelectedText] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [note, setNote] = useState('');
  const [customStartDate, setCustomStartDate] = useState([null]); // Initialize with an array for each page
  const [customEndDate, setCustomEndDate] = useState([null]); // Initialize with an array for each page
  const editorRef = useRef(null);
  const [pages, setPages] = useState([{ content: initialContent, charts: [] }]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(["This Year"]); // Initialize with an array for each page
  const [reportCharts, setReportCharts] = useState([]); // Initialize with no charts for the first page
  const [showChartSettingsForm, setShowChartSettingsForm] = useState(false);
  const [editingChart, setEditingChart] = useState(null); // Add editingChart state
  const authContext = useContext(AuthContext); // Define authContext

  const handleContentChange = (newContent) => {
    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[currentPageIndex].content = newContent;
      return updatedPages;
    });
  };

  const addNewPage = () => {
    const newPageContent = '# Title\n';
    setPages((prevPages) => [...prevPages, { content: newPageContent, charts: [] }]);
    setCurrentPageIndex(pages.length);
    setContent(newPageContent);
    setReportCharts([]); // Add an empty array for the new page's charts
    setSelectedPeriod((prevPeriods) => [...prevPeriods, prevPeriods[prevPeriods.length - 1] || "This Year"]); // Set the new page's period to the same as the previous page
    setCustomStartDate((prevDates) => [...prevDates, prevDates[prevDates.length - 1] || null]); // Set the new page's custom start date to the same as the previous page
    setCustomEndDate((prevDates) => [...prevDates, prevDates[prevDates.length - 1] || null]); // Set the new page's custom end date to the same as the previous page
  };

  const goToPage = (index) => {
    if (index >= 0 && index < pages.length) {
      setCurrentPageIndex(index);
      setContent(pages[index].content);
      setReportCharts(pages[index].charts);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedPages = Array.from(pages);
    const [movedPage] = reorderedPages.splice(result.source.index, 1);
    reorderedPages.splice(result.destination.index, 0, movedPage);

    setPages(reorderedPages);
    setCurrentPageIndex(result.destination.index);
  };

  const handleAddChart = () => {
    const newChart = {
      i: `chart-${reportCharts.length}`,
      x: (reportCharts.length * 2) % 12,
      y: Infinity,
      w: 5,
      h: 7,
      chartType: 'bar',
      chartData: null,
      chartTitle: 'New Chart',
      queryOptions: { dataGroups: [{"dataType": 'Accounts', "dataVal":"Balance","groupBy":"Account Type", "rowLimit": 10, "filters": []}], showLegend: false }
    };
    setEditingChart(newChart);
    setShowChartSettingsForm(true);
  };

  const addItem = (newChart) => {
    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[currentPageIndex] = {
        ...updatedPages[currentPageIndex],
        charts: [...updatedPages[currentPageIndex].charts, newChart],
      };
      return updatedPages;
    });
    setReportCharts((prevCharts) => [...prevCharts, newChart]);
    console.log(reportCharts);
  };

  useEffect(() => {
    setContent(pages[currentPageIndex].content);
    setReportCharts(pages[currentPageIndex].charts);
  }, [currentPageIndex]);

  const handleTextSelection = (e) => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      setSelectedText(selection.toString());

      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setMenuPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
    } else if (!e.target.closest('.ai-menu')) {
      setShowMenu(false);
    }
  };

  const handleAIAction = async (action) => {
    const prompt = `Perform the following action: ${action} on the selected text: "${selectedText}"`;
    // try {
    //   const response = await openai.createCompletion({
    //     model: 'text-davinci-003',
    //     prompt,
    //     max_tokens: 100,
    //   });
    //   const aiText = response.data.choices[0].text.trim();
    //   setContent((prevContent) => prevContent.replace(selectedText, aiText));
    // } catch (error) {
    //   console.error('Error with OpenAI API:', error);
    // }
    const aiText = `AI response for action: ${action} on text: "${selectedText}"`; // Placeholder for actual AI response
    setContent((prevContent) => prevContent.replace(selectedText, aiText));
    setShowMenu(false);
  };

  const makeShorter = () => handleAIAction('Make shorter');
  const expand = () => handleAIAction('Make longer');

  const handleGoButton = () => {
    handleAIAction(note);
    setNote('');
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleTextSelection);
    return () => document.removeEventListener('mouseup', handleTextSelection);
  }, []);

  useEffect(() => {
    console.log(content);
    // Re-render the markdown content after it is updated
    if (editorRef.current) {
      editorRef.current.setMarkdown(content);
    }
  }, [content]);


  useEffect(() => {
    setContent(initialContent);
  }, []);

  const getPeriodDates = (period, pageIndex) => {
    const now = new Date();
    let start_date, end_date;

    switch (period) {
      case 'This Month':
        start_date = new Date(now.getFullYear(), now.getMonth(), 1);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last Month':
        start_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end_date = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'This Quarter':
        const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
        start_date = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), currentQuarter * 3, 0);
        break;
      case 'Last Quarter':
        const lastQuarter = Math.floor((now.getMonth() + 3) / 3) - 1;
        start_date = new Date(now.getFullYear(), (lastQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), lastQuarter * 3, 0);
        break;
      case 'This Year':
        start_date = new Date(now.getFullYear(), 0, 1);
        end_date = new Date(now.getFullYear(), 11, 31);
        break;
      case 'Last Year':
        start_date = new Date(now.getFullYear() - 1, 0, 1);
        end_date = new Date(now.getFullYear() - 1, 11, 31);
        break;
      case 'All Time':
        start_date = undefined;
        end_date = undefined;
        break;
      default:
        start_date = customStartDate[pageIndex];
        end_date = customEndDate[pageIndex];
        break;
    }

    return { start_date, end_date };
  };

  const fetchChartData = async (chartItem) => {
    const { start_date, end_date } = getPeriodDates(selectedPeriod[currentPageIndex], currentPageIndex);
    console.log(chartItem);
    let url = `/api/appData/reportData/${authContext.user.app_connection_id}?dataGroups=${JSON.stringify(chartItem?.queryOptions?.dataGroups || [])}`;
    if (start_date && end_date) {
      url += `&start_date=${start_date.toISOString()}&end_date=${end_date.toISOString()}`;
    }
    if (chartItem.queryOptions.dataGroups[0].rowLimit) {
      url += `&rowLimit=${chartItem.queryOptions.dataGroups[0].rowLimit}`;
    }
    console.log(url);
    const response = await axios.get(url);
    const data = response.data.data;
    return { ...chartItem, chartData: data };
  };

  useEffect(() => {
    const fetchAllChartData = async () => {
      const updatedCharts = await Promise.all(reportCharts.map(async (chartItem) => {
        return await fetchChartData(chartItem);
      }));
      setReportCharts(updatedCharts);
    };
    fetchAllChartData();
  }, [selectedPeriod, customStartDate, customEndDate, currentPageIndex]);

  const saveChartSettings = async (updatedItem) => {
    const updatedItemWithData = await fetchChartData(updatedItem);
    const isExistingChart = reportCharts.some(item => item.i === updatedItemWithData.i);

    if (isExistingChart) {
      const updatedItems = reportCharts.map(item =>
        item.i === updatedItemWithData.i ? updatedItemWithData : item
      );
      setReportCharts(updatedItems);
    } else {
      addItem(updatedItemWithData); // Call addItem to add the new chart
    }
    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[currentPageIndex].charts = reportCharts.map(item =>
        item.i === updatedItemWithData.i ? updatedItemWithData : item
      );
      return updatedPages;
    });
    setEditingChart(null);
    setShowChartSettingsForm(false);
  };

  const closePanel = () => {
    setEditingChart(null);
    setShowChartSettingsForm(false);
  };

  const getChartOptions = (item) => {
    return {
      ...chartOptions,
      legend: {
        ...chartOptions.legend,
        display: item.showLegend
      }
    };
  };


  useEffect(() => {
    // refreshChartData();
  }, [selectedPeriod, customStartDate, customEndDate]);

  return (
    <MDXProvider>
      <div style={{ display: 'flex', height: 'calc(100vh - 40px)', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ width: '200px', backgroundColor: '#f4f4f4', padding: '10px', boxShadow: '2px 0 5px rgba(0,0,0,0.1)' }}>
            <h3>Pages</h3>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-pages">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0 }}>
                    {pages.map((page, index) => (
                      <Draggable key={index} draggableId={`page-${index}`} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              marginBottom: '10px',
                              cursor: 'pointer',
                              padding: '5px',
                              backgroundColor: currentPageIndex === index ? '#ddd' : 'transparent',
                            }}
                            onClick={() => goToPage(index)}
                          >
                            Page {index + 1}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button onClick={addNewPage} style={{ width: '100%' }}>Add New Page</button>
          </div>
          <div style={{ flex: 1, padding: '20px', position: 'relative', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              
              <Dropdown
                value={selectedPeriod[currentPageIndex]}
                options={dataPeriods}
                onChange={(e) => setSelectedPeriod((prevPeriods) => {
                  const updatedPeriods = [...prevPeriods];
                  updatedPeriods[currentPageIndex] = e.value;
                  return updatedPeriods;
                })}
                placeholder="Select Period"
                style={{ width: '200px' }}
              />
              {selectedPeriod[currentPageIndex] === 'Custom' && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Calendar
                    value={customStartDate[currentPageIndex]}
                    onChange={(e) => setCustomStartDate((prevDates) => {
                      const updatedDates = [...prevDates];
                      updatedDates[currentPageIndex] = e.value;
                      return updatedDates;
                    })}
                    placeholder="Start Date"
                    showIcon
                    style={{ marginRight: '10px' }}
                  />
                  <Calendar
                    value={customEndDate[currentPageIndex]}
                    onChange={(e) => setCustomEndDate((prevDates) => {
                      const updatedDates = [...prevDates];
                      updatedDates[currentPageIndex] = e.value;
                      return updatedDates;
                    })}
                    placeholder="End Date"
                    showIcon
                  />
                </div>
              )}
              <button onClick={handleAddChart} style={{ marginLeft: '10px' }}>Add Chart</button>
            </div>
            
            <MDXEditor
              markdown={content}
              onChange={handleContentChange}
              ref={editorRef}
              suppressHtmlProcessing={false}
              plugins={[
                codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),
                headingsPlugin(),
                toolbarPlugin({
                  toolbarClassName: 'my-classname',
                  toolbarContents: () => (
                    <>
                      <UndoRedo />
                      <BoldItalicUnderlineToggles />
                      <BlockTypeSelect />
                      <CreateLink />
                      <InsertTable />
                      <InsertThematicBreak />
                      <ListsToggle />
                      
                    </>
                  ),
                }),
                tablePlugin(),
                imagePlugin(),
                linkDialogPlugin(),
                thematicBreakPlugin(),
                listsPlugin(),
                jsxPlugin(), // Add jsxPlugin to render custom components
              ]}
            />

            {selectedText && (
              <button
                style={{
                  position: 'absolute',
                  top: menuPosition.top,
                  left: menuPosition.left - 30,
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  zIndex: 1000,
                }}
                onClick={() => setShowMenu(!showMenu)}
              >
                <AiOutlineRobot size={20} />
              </button>
            )}

            {showMenu && (
              <div
                className="ai-menu"
                style={{
                  position: 'absolute',
                  top: menuPosition.top,
                  left: menuPosition.left - 150,
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  zIndex: 1000,
                }}
              >
                <button onClick={makeShorter}>Make Shorter</button>
                <button onClick={expand}>Make Longer</button>
                <div style={{ marginTop: '10px' }}>
                  <input
                    type="text"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Type notes here..."
                    style={{ marginRight: '5px' }}
                  />
                  <button onClick={handleGoButton}>Go</button>
                </div>
              </div>
            )}

            <div style={{ flex: 1, overflow: 'auto' }}>
              <div className="report-container">
                <GridLayout
                  className="layout"
                  layout={reportCharts}
                  cols={12}
                  rowHeight={30}
                  width={1200}
                  onLayoutChange={(layout) => setReportCharts(layout)}
                >
                  {reportCharts.map((item) => (
                    <div key={item.i} data-grid={item} className="chart-container">
                      <div className="chart-header">
                        <h3>{item.chartTitle}</h3>
                        <div className="chart-actions">
                          <button onClick={() => setEditingChart(item)}>
                            <FeatherIcon icon="edit" size={16} />
                          </button>
                          <button onClick={() => setReportCharts(reportCharts.filter((chart) => chart.i !== item.i))}>
                            <FeatherIcon icon="trash-2" size={16} />
                          </button>
                        </div>
                      </div>
                      <div className="chart-content">
                        <Chart
                          type={item.chartType}
                          loading={!item.chartData}
                          data={item.chartData}
                          options={getChartOptions(item)}
                        />
                      </div>
                    </div>
                  ))}
                </GridLayout>
              </div>
              {showChartSettingsForm && (
                <div className="side-panel">
                  <div className="side-panel-content">
                    <ChartSettingsForm item={editingChart || { chartType: 'bar', chartTitle: '', queryOptions: { dataGroups: [{"dataType": 'Accounts', aggregation: 'SUM', "dataVal":"Balance","groupBy":"Account Type", "rowLimit": 10, "filters": []}], showLegend: false } }} onSave={saveChartSettings} onCancel={closePanel} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MDXProvider>
  );
}

