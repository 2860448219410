export const chartOptions = {
    "responsive": true,
    "maintainAspectRatio": true,
    "legend": {
        "display": true,
        "position": "right" // Always display the legend to the right
    },
    "title": {
        "text": "asdfasdfsafsadfasdf"
    }
};

export const defaultCharts = [
    {
        "name": "Page 0",
        "title": "Business Summary",
        "charts": [
            {
                "i": "chart-0",
                "x": 0,
                "y": 0,
                "w": 10,
                "h": 8,
                "chartType": "line",
                "chartTitle": "Revenue & Expenses",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "GL Reports",
                            "dataVal": "revenue_hdr",
                            "groupBy": "",
                            "rowLimit": null,
                            "filters": []
                        },
                        {
                            "dataType": "GL Reports",
                            "dataVal": "expenses_hdr",
                            "groupBy": "",
                            "rowLimit": null,
                            "filters": []
                        }
                    ],
                    "showLegend": true
                },
                color: ["green", "red"]
            },
            {
                "i": "chart-2",
                "x": 0,
                "y": 9,
                "w": 5,
                "h": 8,
                "chartType": "line",
                "chartTitle": "Net Income",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "GL Reports",
                            "dataVal": "netIncome_hdr",
                            "groupBy": "",
                            "rowLimit": null,
                            "filters": []
                        }
                    ],
                    "showLegend": false
                }
            }
            ,
            {
                "i": "chart-3",
                "x": 5,
                "y": 9,
                "w": 5,
                "h": 8,
                "chartType": "line",
                "chartTitle": "Cash on Hand",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "GL Reports",
                            "dataVal": "cashOnHand_hdr",
                            "groupBy": "",
                            "rowLimit": null,
                            "filters": []
                        }
                    ],
                    "showLegend": false
                }
            }
        ] 
    },
    {
        "name": "Page 1",
        "title": "Accounts Payable",
        "charts": [
            {
                "i": "chart-0",
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Expenses by Account Type",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "GL Reports",
                            "dataVal": "trialBalanceAmount",
                            "groupBy": "accountType",
                            "rowLimit": 100,
                            "filters": []
                        }
                    ],
                    "showLegend": false
                }
            },
            {
                "i": "chart-2",
                "x": 5,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Purchase Count > $10",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Purchase",
                            "aggregation": "COUNT",
                            "dataVal": "TotalAmt",
                            "groupBy": "TxnDate",
                            "rowLimit": null,
                            "filters": [
                                {
                                    "field": "TotalAmt",
                                    "operator": "<",
                                    "value": "10",
                                    "logicalOperator": "AND",
                                    "expressionVal": "numeric"
                                },
                                {
                                    "field": "TotalAmt",
                                    "operator": ">",
                                    "value": "1000",
                                    "logicalOperator": "OR",
                                    "expressionVal": "numeric"
                                }
                            ],
                            "groupByTime": "month"
                        }
                    ],
                    "showLegend": false
                }
            }
        ] 
    },
    {
        "name": "Page 2",
        "title": "Customers & Vendors",
        "charts": [
            {
                "i": "chart-1",
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Customers with Balance > $300",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Customer",
                            "aggregation": "SUM",
                            "dataVal": "Balance",
                            "groupBy": "FullyQualifiedName",
                            "filters": [
                                {
                                    "field": "Balance",
                                    "operator": ">",
                                    "value": "300",
                                    "logicalOperator": "AND",
                                    "expressionVal": "numeric"
                                }
                            ]
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                },
            },
            {
                "i": "chart-3",
                "x": 0,
                "y": 24,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Vendor 1099s",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Vendor",
                            "aggregation": "COUNT",
                            "dataVal": "Balance",
                            "groupBy": "Vendor1099",
                            "filters": []
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-4",
                "x": 5,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Vendors with Balance > $100",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Vendor",
                            "aggregation": "SUM",
                            "dataVal": "Balance",
                            "groupBy": "DisplayName",
                            "filters": [
                                {
                                    "field": "Balance",
                                    "operator": ">",
                                    "value": "100",
                                    "logicalOperator": "AND",
                                    "expressionVal": "numeric"
                                }
                            ]
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-5",
                "x": 5,
                "y": 8,
                "w": 5,
                "h": 8,
                "chartType": "line",
                "chartTitle": "Vendors by Postal Code",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Vendor",
                            "aggregation": "COUNT",
                            "dataVal": "Balance",
                            "groupBy": "BillAddr.PostalCode",
                            "filters": []
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-6",
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "line",
                "chartTitle": "Top 10 Customer Postal Codes",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Customer",
                            "aggregation": "COUNT",
                            "dataVal": "Balance",
                            "groupBy": "BillAddr.PostalCode",
                            "filters": [],
                            "rowLimit": 10
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            }
        ]
    },
    {
        "name": "Page 3",
        "title": "Items & Services",
        "charts": [
            {
                "i": "chart-2",
                "x": 5,
                "y": 0,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Top 10 Priced Items",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Item",
                            "aggregation": "SUM",
                            "dataVal": "UnitPrice",
                            "groupBy": "FullyQualifiedName",
                            "filters": [],
                            "rowLimit": 10
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-3",
                "x": 0,
                "y": 8,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Top 10 Items by Cost",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Item",
                            "aggregation": "SUM",
                            "dataVal": "PurchaseCost",
                            "groupBy": "Name",
                            "filters": [],
                            "rowLimit": 10
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-4",
                "x": 5,
                "y": 8,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Item Types",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Item",
                            "aggregation": "COUNT",
                            "dataVal": "Balance",
                            "groupBy": "Type",
                            "filters": []
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            },
            {
                "i": "chart-5",
                "x": 0,
                "y": 16,
                "w": 5,
                "h": 8,
                "chartType": "bar",
                "chartTitle": "Items by Income Account",
                "queryOptions": {
                    "dataGroups": [
                        {
                            "dataType": "Item",
                            "aggregation": "COUNT",
                            "dataVal": "Balance",
                            "groupBy": "IncomeAccountRef.name",
                            "filters": []
                        }
                    ],
                    "showLegend": false,
                    "filters": []
                }
            }
        ]
    }
];


export const dataPeriods = [
  "This Month",
  "Last Month",
  "This Quarter",
  "Last Quarter",
  "This Year",
  "Last Year",
  "Last 12 Months",
  "Last 3 Years",
  "Last 5 Years",
  "Custom"
];

export const data_vals = [
    {
        key: "trialBalanceAmount",
        label: "Balance",
        dataType: "number"
    },
    {
        key: "profitAndLossAmount",
        label: "Gain/Loss",
        dataType: "number"
    },
    {
        key: "revenue_hdr",
        label: "Revenue",
        dataType: "number"
    },
    {
        key: "expenses_hdr",
        label: "Expense",
        dataType: "number"
    },
    {
        key: "netIncome_hdr",
        label: "Net Income",
        dataType: "number"
    },
    {
        key: "cashOnHand_hdr",
        label: "Cash on Hand",
        dataType: "number"
    },
    {
        key: "accountType",
        label: "Account Type",
        dataType: "string"
    },
    {
        key: "accountName",
        label: "Account Name",
        dataType: "string"
    },
];

export const group_by = [
    {
        key: "accountType",
        label: "Account Type"
    },
    {
        key: "accountName",
        label: "Account Name"
    },
    {
        key: "start_date",
        label: "Month"
    }
];


export const aggregationOptions = [
  { label: 'Sum', value: 'SUM' },
  { label: 'Average', value: 'AVG' },
  { label: 'Count', value: 'COUNT' },
//   { label: 'Number of Distinct Values', value: 'DISTINCT' }
];

export const datetimeGroupByOptions = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Year', value: 'year' },
  { label: 'None', value: 'none' }
];